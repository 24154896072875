// Font settings
$base-font:                "Campton W00 Light", Helvetica, Arial;
$base-font-size-px:			16;
$base-font-size-percent:	$base-font-size-px * 100 / 16;
$base-font-size-rem:		$base-font-size-px / 16;

$bold-font:                 "Campton W00 Bold", Helvetica, Arial;

// Grid settings
$gutter-width: 10px;

$siteWidth: 1200px + ($gutter-width * 2);

$extra-small-screen: 	319px !default;
$small-screen: 			575px !default;
$medium-screen: 		767px !default;
$large-screen: 			991px !default;
$extra-large-screen: 	1199px !default;

$extra-small-screen-up: ($extra-small-screen + 1px) !default; 	// 320px - iPhone 5 and down
$small-screen-up: 		($small-screen + 1px) !default; 		// 576px - iPhone 6 upto Tablet Vertical
$medium-screen-up: 		($medium-screen + 1px) !default;		// 768px - iPad upto small desktop
$large-screen-up: 		($large-screen + 1px) !default;			// 992px - small desktop/tablet horionztal upto large desktop 
$extra-large-screen-up: ($extra-large-screen + 1px) !default;	// 1200px - larger desktops and up

$extra-small-and-down: 	"only screen and (max-width: #{$extra-small-screen-up})";	// max-width: 320px
$small-and-down: 		"only screen and (max-width: #{$small-screen})";			// max-width: 575px
$medium-and-down: 		"only screen and (max-width: #{$medium-screen})";			// max-width: 767px
$large-and-down: 		"only screen and (max-width: #{$large-screen})";			// max-width: 991px
$extra-large-and-down: 	"only screen and (max-width: #{$extra-large-screen})";		// max-width: 1119px

$extra-small-and-up: 	"only screen and (min-width: #{$extra-small-screen-up})";	// min-width: 320px
$small-and-up: 			"only screen and (min-width: #{$small-screen-up})";			// min-wdith: 576px
$medium-and-up: 		"only screen and (min-width: #{$medium-screen-up})";		// min-width: 768px
$large-and-up: 			"only screen and (min-width: #{$large-screen-up})";			// min-width: 992px
$extra-large-and-up: 	"only screen and (min-width: #{$extra-large-screen-up})";	// min-width: 1200px

// Colours
$link:                  #31ace5;
$off-white:             #f0f0f0;
$off-black:             #322f2d;

$medium-red-voilet:     #c32d7a;
$shocking:              #ef9abd;
$onahau:                #c9e2ee;
$pray:                  #82c1d9;
$sunglow:               #ffc62e;
$my-sin:                #ffb326;
$dull-lavender:         #b291f1;
$faded-blue:            #947cf1;

// Gradients
$medium-red-voilet-to-shocking:     linear-gradient(135deg, $medium-red-voilet 0%, $shocking 100%);
$pray-to-onahau:                    linear-gradient(135deg, $pray 0%, $onahau 100%);
$my-sin-to-sunglow:                 linear-gradient(135deg, $my-sin 0%, $sunglow 100%);
$faded-blue-to-dull-lavender:       linear-gradient(135deg, $faded-blue 0%, $dull-lavender 100%);

$gradient-map: (
    // ClassName, Gradient, FontColour
    ('pink', $medium-red-voilet-to-shocking, white, '../images/John-McDermott.png'),
    ('blue', $pray-to-onahau, white, '../images/Inderjit-Mund.png'),
    ('orange', $my-sin-to-sunglow, white, '../images/Gavin-Shore.png'),
    ('purple', $faded-blue-to-dull-lavender, white, '../images/Maria-Vardy-V2.png')
);

// Box Shadow
$box-shadow:            0px 0px 19px 1px rgba(0, 0, 0, 0.1);

// Icons
$icon-speech-marks:     url('../images/icons/speech-marks.svg');