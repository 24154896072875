.arrow-link {
    display: inline-block;
    color: $link;
    text-decoration: none;
    vertical-align: baseline;

    .arrow-anim {
        display: inline-block;
        width: 14px;
        vertical-align: middle;
    }
}