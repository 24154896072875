* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding-top: 59px;
}

body.noscroll {
    overflow: hidden;
    height: 100%;
}

div.container {
    width: 100%;
    max-width: $siteWidth;
    margin: 0 auto;

    &.container--full {
        max-width: 100%;
        overflow: hidden;
    }

    &.container--center {
        @include vertical-align(absolute);
        max-width: none;
    }
}

video {
    max-width: 100%;
}

img {
    vertical-align: bottom;
}

blockquote {
    margin: 0;
}

.hide {
    display: none;
}
.show--sm-down, .show--md-down, .show--lg-down, .show-xl-down,
.show--sm-up, .show--md--up, .show--lg-up, .show--xl-up {
    display: none;
}

@media #{$small-and-down} {
    .hide--sm-down {
        display: none!important;
    }
    .show--sm-down {
        display: block!important;
    }
}
@media #{$small-and-up} {
    .hide--sm-up {
        display: none!important;
    }
    .show--sm-up {
        display: block!important;
    }
}

@media #{$medium-and-down} {
    .hide--md-down {
        display: none!important;
    }
    .show--md-down {
        display: block!important;
    }
}
@media #{$medium-and-up} {
    .hide--md-up {
        display: none!important;
    }
    .show--md-up {
        display: block!important;
    }
}

@media #{$large-and-down} {
    .hide--lg-down {
        display: none!important;
    }
    .show--lg-down {
        display: block!important;
    }
}
@media #{$large-and-up} {
    .hide--lg-up {
        display: none!important;
    }
    .show--lg-up {
        display: block!important;
    }
}

@media #{$extra-large-and-up} {
    .hide--xl-down {
        display: none!important;
    }
    .show--xl-down {
        display: block!important;
    }
}
@media #{$extra-large-and-up} {
    .hide--xl-up {
        display: none!important;
    }
    .show--xl-up {
        display: block!important;
    }
}