.orbs {
    min-height: 700px;
    overflow: hidden;

    @media #{$medium-and-down} {
        min-height: 500px;
    }

    .orb {
        position: absolute;

        &--1 {
            width: 50px;
            height: 50px;
            top: 10%;
            left: 47%;
            // animation: floating-3 7.5s ease-in-out infinite;

            @media #{$medium-and-down} {
                display: none;
            }
        }

        &--2 {
            width: 120px;
            height:120px;
            top: 67%;
            left: 24%;
            // animation: floating 7.5s ease-in-out infinite;
        }

        &--3 {
            width: 25px;
            height: 25px;
            top: 20%;
            left: 74%;
            // animation: floating-3 7.5s ease-in-out infinite;
        }

        &--4 {
            width: 50px;
            height: 50px;
            top: 52%;
            left: 10%;
            // animation: floating-4 7.5s ease-in-out infinite;

            @media #{$large-and-down} {
                display: none;
            }
        }

        &--5 {
            width: 250px;
            height: 250px;
            top: 57%;
            left: 76%;
            // animation: floating-2 7.5s ease-in-out infinite;

            @media #{$large-and-down} {
                top: 65%;
            }

            @media #{$medium-and-down} {
                display: none;
            }
        }

        &--6 {
            width: 128px;
            height: 128px;
            top: 9%;
            left: 15%;
            // animation: floating 7.5s ease-in-out infinite;

            @media #{$medium-and-down} {
                // animation: floating-2 7.5s ease-in-out infinite;
            }
        }
        
    }
}

@keyframes floating {
    from {
        transform:translate(0, 0);
    }
    65% {
        transform:translate(0, 10%);
    }
    to {
        transform: translate(0, 0);
    }    
}

@keyframes floating-2 {
    from {
        transform:translate(0, 0);
    }
    45% {
        transform:translate(0, -25%);
    }
    to {
        transform: translate(0, 0);
    }    
}

@keyframes floating-3 {
    from {
        transform:translate(0, 0);
    }
    65% {
        transform:translate(0, 100%);
    }
    to {
        transform: translate(0, 0);
    }    
}

@keyframes floating-4 {
    from {
        transform:translate(0, 0);
    }
    45% {
        transform:translate(0, -125%);
    }
    to {
        transform: translate(0, 0);
    }    
}