// Use in place of setting font-size and line-height - Note: Do not use on pseudo elements (:before,:after) due to a duplicate rules bug in IE11 and down
@mixin font-metrics( $font-size-px, $line-height-px: 0, $letter-spacing-px: 0 ) {
    $font-size-rem: $font-size-px / $base-font-size-px;
    font-size: ($font-size-px) + px;
    font-size: ($font-size-rem) + rem;

    @if $line-height-px != 0 {
        $line-height-rem: $line-height-px / $base-font-size-px;
        line-height: ($line-height-px) + px;
        line-height: ($line-height-rem) + rem;
    }

    @if $letter-spacing-px != 0 {
        $letter-spacing-rem: $letter-spacing-px / $base-font-size-px;
        letter-spacing: ($letter-spacing-px) + px;
        letter-spacing: ($letter-spacing-rem) + rem;
    }
}

// CSS animation mixins
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

// Positioning mixins
@mixin vertical-align($position: relative){
    position: $position;
    top:50%;
    transform: translateY(-50%);
}

@mixin headings() {
    h1, h2, h3,
    h4, h5, h6 {
        @content;
    }
}