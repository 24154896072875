$rolling-text-height: 100px;

.rolling-text {
    user-select: none;

    .js-rolling-word{
        position: relative;
        top: 0;
        width: 100%;
        height: $rolling-text-height;
        overflow: hidden;

        @media #{$medium-and-down} {
            height: 58px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 10px;
            top: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            z-index: 10;
        }

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 10px;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            z-index: 10;
        }
    }

    span {
        transition: top 0.5s ease;
        position: absolute;
        width: 100%;
        left: 0;
    
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }

        &.to-be-displayed {
            top: $rolling-text-height;
            @media #{$medium-and-down} {
                top: 58px;
            }
        }

        &.active {
            top: 0;
        }

        &.been-displayed {
            top: -#{$rolling-text-height};
            @media #{$medium-and-down} {
                top: -58px;
            }
        }
    }

    
}