section {
    padding: 50px 0;
    position: relative;
    z-index: 1;
    
    &.break-out { 

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }

        &:before,
        &:after {
            content: '';
            width: 100%;
            height: 150px;
            position: absolute;
            left: 0;
            background-color: white;
            z-index: -1;
        }
    }
}

hr {
    border-color: #e5e5e5;
    border-top: 0;
    margin: 30px 0;
}

ul {
    padding-left: 20px;
    margin: 0;
}

.background {

    &--white {
        background-color: white;
        box-shadow: $box-shadow;
        
    }

    &--off-white {
        background-color: $off-white;
    }
}

.content-padding {
    padding: 100px calc(8.33333% + (#{$gutter-width} * 2)); // 8.3333% = 1 Grid Width

    @media #{$large-and-down} {
        padding: 50px calc(#{$gutter-width} * 2);
    }

    &--sides-only {
        padding: 0 calc(8.33333% + (#{$gutter-width} * 2));

        @media #{$large-and-down} {
            padding: 0 calc(#{$gutter-width} * 2);
        }
    }
}

.contact {
    margin-top: 50px;
    h3 {
        margin: 0;
        margin-bottom: 10px;
    }
    
    p {
        margin: 0;
        color: black;
        @include font-metrics(20, 28);

        a {
            color: inherit;
            text-decoration: none;        
        }
    }
}

.hero-video {
    width: 650px;

    @media #{$large-and-down} {
        width: 500px;
    }
}