footer {
    background: black;
    padding: 15px 0;

    .footer-social {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        div {
            padding: 0 10px;
        }

        @media #{$medium-and-down} {
            justify-content: center;
            margin-bottom: 20px;
        }
    }

    .footer-copyright {
        color: #666666;
        @include font-metrics(14, 20);

        @media #{$medium-and-down} {
            text-align: center;
            @include font-metrics(12, 18);
        }
    }

    .footer-info {
        @media #{$medium-and-down} {
            margin-bottom: 20px;

            a {
                width: 100%;
                display: inline-block;
                text-align: center;        
            }
        }
    }

    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;

            @media #{$medium-and-down} {
                justify-content: center;
                margin-bottom: 20px;
            }

            li {
                padding: 0 10px;
                line-height: 29px;

                a {
                    @include font-metrics(14, 20);
                }

            }
        }
    }

    a {
        color: white;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid #fff;
        }
    }
}