header {
    background: black;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;

    .logo {
        height: 25px;

        a {
            height: inherit;

            img {
                height: inherit!important;
                width: 150px!important; 
            }
        }
    }

    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-content: center;

            li {
                padding: 0 20px;
                line-height: 29px;

                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }
    }
}
