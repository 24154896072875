.slider {
    // .slider-content
    &-content {

        .slick-arrow {
            &.arrow-wrapper {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background-color: white;
                box-shadow: $box-shadow;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                z-index: 10;
                cursor: pointer;
                overflow: hidden;

                &.prev-arrow {
                    left: 15%;
                }

                &.next-arrow {
                    right: 15%;
                }

                .arrow-anim {
                    width: 15px;
                    margin: 0;
                    top: 50%;
                    left: 50%;
                    height: 26px;
                    transform: translate(-50%, -50%);
                    position: relative;

                    &--flipped {
                        transform: translate(-50%, -50%) rotateY(180deg);
                    }

                    svg {
                        path {
                            stroke: $off-black !important;
                        }
                    }
                }
            }
        }

    }

    // .slider-item
    &-item {
        max-width: 1116px;
        padding: 10px 50px;

        @media #{$extra-large-and-down} {
            max-width: 100%;
            padding: 10px 28px;
        }

        @media #{$large-and-down} {
            padding: 10px 0;
        }

        // .slider-item__quote
        &__quote {
            position: relative;

            blockquote {
                padding-top: 30px;
                padding-bottom: 30px;

                &:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    background-image: $icon-speech-marks;
                    background-size: contain;
                    background-repeat: no-repeat;
                    top: 0;
                    left: 0;
                    width: 56px;
                    height: 39px;
                }

                @media #{$medium-and-down} {
                    text-align: center;
                    @include font-metrics(18, 26);
                }
            }

            // .slider-item__quote-image
            &-image {
                img {
                    position: absolute;
                    bottom: 0;
                }
            }
        }

        // .slider-item__download
        &__download {
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 30px;
            padding-bottom: 30px;

            // .slider-item__download-image
            &-image {
                width: 100px;
                margin-right: 8.333333%;
            }
        }
    }
}