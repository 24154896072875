.staff {
    display: flex;
    flex-direction: row;
    margin: 50px 0;

    @media #{$large-and-down} {
        flex-wrap: wrap;
        justify-content: center;
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
        padding: 0 5px;
        width: 20%;

        @media #{$large-and-down} {
            width: 33%;
            margin: 10px 0;
        }

        @media #{$medium-and-down} {
            width: 50%;
            margin: 10px 0;
        }

        p{
            margin: 0;
            @include font-metrics(12, 20);
        }
    }

    &__image {
        width: 100%;
        max-height: 150px;

        @media #{$large-and-down} {
            max-height: none;
        }
    }
}