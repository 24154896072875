.text-left{
	text-align: left !important;
}
.text-center{
	text-align: center !important;

	&--tablet {
		@media #{$large-and-down} {
			text-align: center !important;
		}
	}

	&--mobile {
		@media #{$medium-and-down} {
			text-align: center !important;
		}
	}
}
.text-right{
	text-align: right !important;
}

.gradient-background {
	@each $className, $gradient, $fontColour, $backgroundImage in $gradient-map {
		&--#{$className} {
			background: $gradient;
			color: $fontColour;
			box-shadow: $box-shadow;

			&:before {
				content: '';
				background-image: url($backgroundImage);
				position: absolute;
				width: 55%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				bottom: 0;
				right: 0;

				@media #{$medium-and-down} {
					content: none;
				}
			}
		}
	}
}