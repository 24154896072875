.container{
    padding: 0 $gutter-width;
}

.row {
    margin-left: -$gutter-width;
    margin-right: -$gutter-width;
    
    > div[class^="col-"], > div[class*=" col-"] {
        position: relative;
        min-height: 1px;
        padding-right: $gutter-width;
        padding-left: $gutter-width;
        float: left;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }

    &.row--no-gutter {
        margin-left: $gutter-width;
        margin-right: $gutter-width;

        > div[class^="col-"], > div[class*=" col-"] {
            padding-right: 0;
            padding-left: 0;
        }

        @media #{$extra-large-and-up} {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    .col-xs-1 {
        width: 8.33333%;
    }

    .col-xs-2 {
        width: 16.66667%;
    }

    .col-xs-3 {
        width: 25%;
    }

    .col-xs-4 {
        width: 33.33333%;
    }

    .col-xs-5 {
        width: 41.66667%;
    }

    .col-xs-6 {
        width: 50%;
    }

    .col-xs-7 {
        width: 58.33333%;
    }

    .col-xs-8 {
        width: 66.66667%;
    }

    .col-xs-9 {
        width: 75%;
    }

    .col-xs-10 {
        width: 83.33333%;
    }

    .col-xs-11 {
        width: 91.66667%;
    }

    .col-xs-12 {
        width: 100%;
    }

    .pull-xs-0 {
        right: auto;
    }

    .pull-xs-1 {
        right: 8.33333%;
    }

    .pull-xs-2 {
        right: 16.66667%;
    }

    .pull-xs-3 {
        right: 25%;
    }

    .pull-xs-4 {
        right: 33.33333%;
    }

    .pull-xs-5 {
        right: 41.66667%;
    }

    .pull-xs-6 {
        right: 50%;
    }

    .pull-xs-7 {
        right: 58.33333%;
    }

    .pull-xs-8 {
        right: 66.66667%;
    }

    .pull-xs-9 {
        right: 75%;
    }

    .pull-xs-10 {
        right: 83.33333%;
    }

    .pull-xs-11 {
        right: 91.66667%;
    }

    .pull-xs-12 {
        right: 100%;
    }

    .push-xs-0 {
        left: auto;
    }

    .push-xs-1 {
        left: 8.33333%;
    }

    .push-xs-2 {
        left: 16.66667%;
    }

    .push-xs-3 {
        left: 25%;
    }

    .push-xs-4 {
        left: 33.33333%;
    }

    .push-xs-5 {
        left: 41.66667%;
    }

    .push-xs-6 {
        left: 50%;
    }

    .push-xs-7 {
        left: 58.33333%;
    }

    .push-xs-8 {
        left: 66.66667%;
    }

    .push-xs-9 {
        left: 75%;
    }

    .push-xs-10 {
        left: 83.33333%;
    }

    .push-xs-11 {
        left: 91.66667%;
    }

    .push-xs-12 {
        left: 100%;
    }

    .offset-xs-1 {
        margin-left: 8.33333%;
    }

    .offset-xs-2 {
        margin-left: 16.66667%;
    }

    .offset-xs-3 {
        margin-left: 25%;
    }

    .offset-xs-4 {
        margin-left: 33.33333%;
    }

    .offset-xs-5 {
        margin-left: 41.66667%;
    }

    .offset-xs-6 {
        margin-left: 50%;
    }

    .offset-xs-7 {
        margin-left: 58.33333%;
    }

    .offset-xs-8 {
        margin-left: 66.66667%;
    }

    .offset-xs-9 {
        margin-left: 75%;
    }

    .offset-xs-10 {
        margin-left: 83.33333%;
    }

    .offset-xs-11 {
        margin-left: 91.66667%;
    }

    @media #{$small-and-up} {
        .col-sm-1 {
            width: 8.33333%;
        }

        .col-sm-2 {
            width: 16.66667%;
        }

        .col-sm-3 {
            width: 25%;
        }

        .col-sm-4 {
            width: 33.33333%;
        }

        .col-sm-5 {
            width: 41.66667%;
        }

        .col-sm-6 {
            width: 50%;
        }

        .col-sm-7 {
            width: 58.33333%;
        }

        .col-sm-8 {
            width: 66.66667%;
        }

        .col-sm-9 {
            width: 75%;
        }

        .col-sm-10 {
            width: 83.33333%;
        }

        .col-sm-11 {
            width: 91.66667%;
        }

        .col-sm-12 {
            width: 100%;
        }

        .pull-sm-0 {
            right: auto;
        }

        .pull-sm-1 {
            right: 8.33333%;
        }

        .pull-sm-2 {
            right: 16.66667%;
        }

        .pull-sm-3 {
            right: 25%;
        }

        .pull-sm-4 {
            right: 33.33333%;
        }

        .pull-sm-5 {
            right: 41.66667%;
        }

        .pull-sm-6 {
            right: 50%;
        }

        .pull-sm-7 {
            right: 58.33333%;
        }

        .pull-sm-8 {
            right: 66.66667%;
        }

        .pull-sm-9 {
            right: 75%;
        }

        .pull-sm-10 {
            right: 83.33333%;
        }

        .pull-sm-11 {
            right: 91.66667%;
        }

        .pull-sm-12 {
            right: 100%;
        }

        .push-sm-0 {
            left: auto;
        }

        .push-sm-1 {
            left: 8.33333%;
        }

        .push-sm-2 {
            left: 16.66667%;
        }

        .push-sm-3 {
            left: 25%;
        }

        .push-sm-4 {
            left: 33.33333%;
        }

        .push-sm-5 {
            left: 41.66667%;
        }

        .push-sm-6 {
            left: 50%;
        }

        .push-sm-7 {
            left: 58.33333%;
        }

        .push-sm-8 {
            left: 66.66667%;
        }

        .push-sm-9 {
            left: 75%;
        }

        .push-sm-10 {
            left: 83.33333%;
        }

        .push-sm-11 {
            left: 91.66667%;
        }

        .push-sm-12 {
            left: 100%;
        }

        .offset-sm-0 {
            margin-left: 0%;
        }

        .offset-sm-1 {
            margin-left: 8.33333%;
        }

        .offset-sm-2 {
            margin-left: 16.66667%;
        }

        .offset-sm-3 {
            margin-left: 25%;
        }

        .offset-sm-4 {
            margin-left: 33.33333%;
        }

        .offset-sm-5 {
            margin-left: 41.66667%;
        }

        .offset-sm-6 {
            margin-left: 50%;
        }

        .offset-sm-7 {
            margin-left: 58.33333%;
        }

        .offset-sm-8 {
            margin-left: 66.66667%;
        }

        .offset-sm-9 {
            margin-left: 75%;
        }

        .offset-sm-10 {
            margin-left: 83.33333%;
        }

        .offset-sm-11 {
            margin-left: 91.66667%;
        }
    }
    @media #{$medium-and-up} {
        .col-md-1 {
            width: 8.33333%;
        }

        .col-md-2 {
            width: 16.66667%;
        }

        .col-md-3 {
            width: 25%;
        }

        .col-md-4 {
            width: 33.33333%;
        }

        .col-md-5 {
            width: 41.66667%;
        }

        .col-md-6 {
            width: 50%;
        }

        .col-md-7 {
            width: 58.33333%;
        }

        .col-md-8 {
            width: 66.66667%;
        }

        .col-md-9 {
            width: 75%;
        }

        .col-md-10 {
            width: 83.33333%;
        }

        .col-md-11 {
            width: 91.66667%;
        }

        .col-md-12 {
            width: 100%;
        }

        .pull-md-0 {
            right: auto;
        }

        .pull-md-1 {
            right: 8.33333%;
        }

        .pull-md-2 {
            right: 16.66667%;
        }

        .pull-md-3 {
            right: 25%;
        }

        .pull-md-4 {
            right: 33.33333%;
        }

        .pull-md-5 {
            right: 41.66667%;
        }

        .pull-md-6 {
            right: 50%;
        }

        .pull-md-7 {
            right: 58.33333%;
        }

        .pull-md-8 {
            right: 66.66667%;
        }

        .pull-md-9 {
            right: 75%;
        }

        .pull-md-10 {
            right: 83.33333%;
        }

        .pull-md-11 {
            right: 91.66667%;
        }

        .pull-md-12 {
            right: 100%;
        }

        .push-md-0 {
            left: auto;
        }

        .push-md-1 {
            left: 8.33333%;
        }

        .push-md-2 {
            left: 16.66667%;
        }

        .push-md-3 {
            left: 25%;
        }

        .push-md-4 {
            left: 33.33333%;
        }

        .push-md-5 {
            left: 41.66667%;
        }

        .push-md-6 {
            left: 50%;
        }

        .push-md-7 {
            left: 58.33333%;
        }

        .push-md-8 {
            left: 66.66667%;
        }

        .push-md-9 {
            left: 75%;
        }

        .push-md-10 {
            left: 83.33333%;
        }

        .push-md-11 {
            left: 91.66667%;
        }

        .push-md-12 {
            left: 100%;
        }

        .offset-md-0 {
            margin-left: 0%;
        }

        .offset-md-1 {
            margin-left: 8.33333%;
        }

        .offset-md-2 {
            margin-left: 16.66667%;
        }

        .offset-md-3 {
            margin-left: 25%;
        }

        .offset-md-4 {
            margin-left: 33.33333%;
        }

        .offset-md-5 {
            margin-left: 41.66667%;
        }

        .offset-md-6 {
            margin-left: 50%;
        }

        .offset-md-7 {
            margin-left: 58.33333%;
        }

        .offset-md-8 {
            margin-left: 66.66667%;
        }

        .offset-md-9 {
            margin-left: 75%;
        }

        .offset-md-10 {
            margin-left: 83.33333%;
        }

        .offset-md-11 {
            margin-left: 91.66667%;
        }
    }
    @media #{$large-and-up} {
        .col-lg-1 {
            width: 8.33333%;
        }

        .col-lg-2 {
            width: 16.66667%;
        }

        .col-lg-3 {
            width: 25%;
        }

        .col-lg-4 {
            width: 33.33333%;
        }

        .col-lg-5 {
            width: 41.66667%;
        }

        .col-lg-6 {
            width: 50%;
        }

        .col-lg-7 {
            width: 58.33333%;
        }

        .col-lg-8 {
            width: 66.66667%;
        }

        .col-lg-9 {
            width: 75%;
        }

        .col-lg-10 {
            width: 83.33333%;
        }

        .col-lg-11 {
            width: 91.66667%;
        }

        .col-lg-12 {
            width: 100%;
        }

        .pull-lg-0 {
            right: auto;
        }

        .pull-lg-1 {
            right: 8.33333%;
        }

        .pull-lg-2 {
            right: 16.66667%;
        }

        .pull-lg-3 {
            right: 25%;
        }

        .pull-lg-4 {
            right: 33.33333%;
        }

        .pull-lg-5 {
            right: 41.66667%;
        }

        .pull-lg-6 {
            right: 50%;
        }

        .pull-lg-7 {
            right: 58.33333%;
        }

        .pull-lg-8 {
            right: 66.66667%;
        }

        .pull-lg-9 {
            right: 75%;
        }

        .pull-lg-10 {
            right: 83.33333%;
        }

        .pull-lg-11 {
            right: 91.66667%;
        }

        .pull-lg-12 {
            right: 100%;
        }

        .push-lg-0 {
            left: auto;
        }

        .push-lg-1 {
            left: 8.33333%;
        }

        .push-lg-2 {
            left: 16.66667%;
        }

        .push-lg-3 {
            left: 25%;
        }

        .push-lg-4 {
            left: 33.33333%;
        }

        .push-lg-5 {
            left: 41.66667%;
        }

        .push-lg-6 {
            left: 50%;
        }

        .push-lg-7 {
            left: 58.33333%;
        }

        .push-lg-8 {
            left: 66.66667%;
        }

        .push-lg-9 {
            left: 75%;
        }

        .push-lg-10 {
            left: 83.33333%;
        }

        .push-lg-11 {
            left: 91.66667%;
        }

        .push-lg-12 {
            left: 100%;
        }

        .offset-lg-0 {
            margin-left: 0%;
        }

        .offset-lg-1 {
            margin-left: 8.33333%;
        }

        .offset-lg-2 {
            margin-left: 16.66667%;
        }

        .offset-lg-3 {
            margin-left: 25%;
        }

        .offset-lg-4 {
            margin-left: 33.33333%;
        }

        .offset-lg-5 {
            margin-left: 41.66667%;
        }

        .offset-lg-6 {
            margin-left: 50%;
        }

        .offset-lg-7 {
            margin-left: 58.33333%;
        }

        .offset-lg-8 {
            margin-left: 66.66667%;
        }

        .offset-lg-9 {
            margin-left: 75%;
        }

        .offset-lg-10 {
            margin-left: 83.33333%;
        }

        .offset-lg-11 {
            margin-left: 91.66667%;
        }
    }
    @media #{$extra-large-and-up} {
        .col-xl-1 {
            width: 8.33333%;
        }

        .col-xl-2 {
            width: 16.66667%;
        }

        .col-xl-3 {
            width: 25%;
        }

        .col-xl-4 {
            width: 33.33333%;
        }

        .col-xl-5 {
            width: 41.66667%;
        }

        .col-xl-6 {
            width: 50%;
        }

        .col-xl-7 {
            width: 58.33333%;
        }

        .col-xl-8 {
            width: 66.66667%;
        }

        .col-xl-9 {
            width: 75%;
        }

        .col-xl-10 {
            width: 83.33333%;
        }

        .col-xl-11 {
            width: 91.66667%;
        }

        .col-xl-12 {
            width: 100%;
        }

        .pull-xl-0 {
            right: auto;
        }

        .pull-xl-1 {
            right: 8.33333%;
        }

        .pull-xl-2 {
            right: 16.66667%;
        }

        .pull-xl-3 {
            right: 25%;
        }

        .pull-xl-4 {
            right: 33.33333%;
        }

        .pull-xl-5 {
            right: 41.66667%;
        }

        .pull-xl-6 {
            right: 50%;
        }

        .pull-xl-7 {
            right: 58.33333%;
        }

        .pull-xl-8 {
            right: 66.66667%;
        }

        .pull-xl-9 {
            right: 75%;
        }

        .pull-xl-10 {
            right: 83.33333%;
        }

        .pull-xl-11 {
            right: 91.66667%;
        }

        .pull-xl-12 {
            right: 100%;
        }

        .push-xl-0 {
            left: auto;
        }

        .push-xl-1 {
            left: 8.33333%;
        }

        .push-xl-2 {
            left: 16.66667%;
        }

        .push-xl-3 {
            left: 25%;
        }

        .push-xl-4 {
            left: 33.33333%;
        }

        .push-xl-5 {
            left: 41.66667%;
        }

        .push-xl-6 {
            left: 50%;
        }

        .push-xl-7 {
            left: 58.33333%;
        }

        .push-xl-8 {
            left: 66.66667%;
        }

        .push-xl-9 {
            left: 75%;
        }

        .push-xl-10 {
            left: 83.33333%;
        }

        .push-xl-11 {
            left: 91.66667%;
        }

        .push-xl-12 {
            left: 100%;
        }

        .offset-xl-0 {
            margin-left: 0%;
        }

        .offset-xl-1 {
            margin-left: 8.33333%;
        }

        .offset-xl-2 {
            margin-left: 16.66667%;
        }

        .offset-xl-3 {
            margin-left: 25%;
        }

        .offset-xl-4 {
            margin-left: 33.33333%;
        }

        .offset-xl-5 {
            margin-left: 41.66667%;
        }

        .offset-xl-6 {
            margin-left: 50%;
        }

        .offset-xl-7 {
            margin-left: 58.33333%;
        }

        .offset-xl-8 {
            margin-left: 66.66667%;
        }

        .offset-xl-9 {
            margin-left: 75%;
        }

        .offset-xl-10 {
            margin-left: 83.33333%;
        }

        .offset-xl-11 {
            margin-left: 91.66667%;
        }
    }
}