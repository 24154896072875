body {
     font-family: $base-font;
     font-size: ($base-font-size-px + px);
     line-height: 26px;
 }

 @include headings() {
    font-family: $bold-font;
    margin-top: 0;
 }

h1 {
    @include font-metrics(44, 52);

    @media #{$medium-and-down} {
        @include font-metrics(36, 48);
    }
}
h2 {
    @include font-metrics(44, 52);

    @media #{$medium-and-down} {
        @include font-metrics(36, 48);
    }
}
h3 {
    @include font-metrics(24, 32);

    &.rolling-text {
        @include font-metrics(44, 52);

        @media #{$medium-and-down} {
            @include font-metrics(32, 36);
        }

        span {
            @include font-metrics(80, 80);

            @media #{$medium-and-down} {
                @include font-metrics(42, 52);
            }
        }
    }
}
h4 {
    @include font-metrics(24, 32);
    margin: 0;
}
h5 {
    @include font-metrics(24, 32);
    margin: 0;
    margin-bottom: 40px;
}
h6 {
    @include font-metrics(16, 24);
    margin: 0;
}

strong {
    font-family: $bold-font;
    font-size: inherit;
}

.sub-heading {
    @include font-metrics(20, 28);

    &--top {
        @include font-metrics(14, 22);
        margin: 0;
        margin-bottom: 5px;
    }

    @media #{$medium-and-down} {
        @include font-metrics(18, 26);
    }
}

blockquote {
    color: inherit;
    font-family: $bold-font;
    @include font-metrics(24, 32);

    .author {
        font-family: $base-font;
        @include font-metrics(14, 22);
    }
}